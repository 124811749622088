import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { usePlaidLink } from "react-plaid-link";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Divider, Grid, Popover } from "@mui/material";

import Button from "components/Button/Button";
import DeleteBankAcc from "components/Screens/PaymentV2/DeleteBankAcc";
import AddEchInfo from "components/Screens/PaymentV2/AddEchInfo";
import PageTitle from "components/Widgets/PageTitle";
import PageLoader from "components/common/PageLoader";

import PaymentIllustration from "assets/images/payment-method-illustration.png";
import InfoIcon from "assets/images/info-question-mark.svg";
import CreditCardIcon from "assets/images/credit-card.svg";
import BankIcon from "assets/images/bank-icon.svg";
import CopyIcon from "assets/images/copy.svg";
import TickIcon from "assets/images/tick-filled.svg";
import ChevronIcon from "assets/images/chevron-right.svg";

import { usePermissions } from "components/Hook";
import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";
import { getMonthYearFromDate } from "helpers";

import { CustomerBankInfo } from "store/actions/Payment/GetCustomerBankInfo";
import { AddCustomerBankInfo, ResetAddCustomerBankInfo } from "store/actions/Payment/AddBankAccountInfo";
import { showSnackbar } from "store/actions/Utility";

import "./Payment.css";

function PaymentMethods() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { platfromRebrandPhase1 } = useFlags();

  const { loggedInUser, loggedInUserType } = usePermissions();

  const [linkToken, setLinkToken] = useState(null);
  const [openEch, setOpenEch] = useState(false);
  const [editAch, setEditAch] = useState(false);
  const [openDeleteAcc, setOpenDeleteAcc] = useState(false);

  const [plaidClosed, setPlaidClosed] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const openInfo = Boolean(anchorEl);
  const id = openInfo ? "simple-popover" : undefined;

  const bankAccountsDetails = useSelector((globalState) => globalState.GetCustomerBankInfo.bankAccountInfo);
  const loadingBankAccountInfo = useSelector((globalState) => globalState.GetCustomerBankInfo.loadingBankAccountInfo);
  const loadingplatformConfig = useSelector((globalState) => globalState.PlatformConfig.loadingplatformConfig);
  const addBankAccountInfo = useSelector((globalState) => globalState.AddBankAccountInfo.addBankAccountInfo);
  const loadingAddBankAccountInfo = useSelector(
    (globalState) => globalState.AddBankAccountInfo.loadingAddBankAccountInfo
  );

  const lastPayrollStatus = bankAccountsDetails?.lastPayrollStatus;
  const lastPayrollFailed = bankAccountsDetails?.lastPayrollFailure;

  const isCreditCard = useMemo(() => {
    if (bankAccountsDetails?.paymentMethodType === "CREDIT_CARD") return true;
    return false;
  }, [bankAccountsDetails]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const getLinkToken = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken != null && accessToken != undefined) {
      const response = await fetch(`${baseUrl}/getLinkToken`, {
        method: "get",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        return;
      }
      const data = await response.json();

      setLinkToken(data);
    }
  };

  const onSuccess = useCallback((publicToken) => {
    setPlaidClosed(false);

    const setToken = async () => {
      const userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));
      const body = {
        customerID: loggedInUser?.userId,
        publicToken,
      };

      const accessToken = localStorage.getItem("accessToken");
      if (accessToken != null && accessToken != undefined) {
        dispatch(
          AddCustomerBankInfo({
            request: `${baseUrl}/customer/${userAdditionalInfo?.customerId}/account`,
            body: JSON.stringify(body),
          })
        );
      }
    };
    setToken();

    navigate("/main/payment");
  }, []);

  const config = {
    token: linkToken?.link_token,
    onSuccess,
    onExit: () => setPlaidClosed(true),
  };
  const { open } = usePlaidLink(config);

  const handleEchClose = () => {
    setOpenEch(false);
  };

  const handleDeleteAccClose = () => {
    setOpenDeleteAcc(false);
  };

  const getBackgroundColor = (isLastPayrollFail, statusOfLastPayroll) => {
    if (isLastPayrollFail) {
      return "#FAE6E8";
    }
    if (statusOfLastPayroll === "pending") {
      return "#FAF4E6";
    }
    return "#ebfae6";
  };

  const getColor = (isLastPayrollFail, statusOfLastPayroll) => {
    if (isLastPayrollFail) {
      return "#DE0E22";
    }
    if (statusOfLastPayroll === "pending") {
      return "#B2840B";
    }
    return "#477e35";
  };

  const getStatusText = (isLastPayrollFail, statusOfLastPayroll) => {
    if (isLastPayrollFail) {
      return "Failed";
    }
    if (statusOfLastPayroll === "pending") {
      return "Processing";
    }
    return "Successful";
  };

  const getFilter = (isLastPayrollFail, statusOfLastPayroll) => {
    if (isLastPayrollFail) {
      return "invert(15%) sepia(99%) saturate(3505%) hue-rotate(343deg) brightness(96%) contrast(103%)";
    }
    if (statusOfLastPayroll === "pending") {
      return "invert(43%) sepia(75%) saturate(1236%) hue-rotate(24deg) brightness(96%) contrast(91%)";
    }
    return "unset";
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText("finance@edgeonline.co");
    handleCloseAnchor();
    dispatch(showSnackbar({ dispatch, type: "success", message: "Email address copied" }));
  };

  const displayInfoIcon = () => {
    return (
      <>
        <button
          type="button"
          className="payment-info-img cursor-pointer"
          aria-describedby={id}
          onClick={handleClick}
          onKeyPress={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              handleClick(event);
            }
          }}
        >
          <img src={InfoIcon} alt="info-icon" />
        </button>

        <Popover
          id={id}
          open={openInfo}
          anchorEl={anchorEl}
          onClose={handleCloseAnchor}
          className="popover-wrapper"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="popover-container">
            <div className="payment-popover-heading"> Need help?</div>
            <div className="payment-popover-desc mt-1">Email us at</div>

            <div
              role="button"
              tabIndex={0}
              className="payment-contact-email-wrapper cursor-pointer"
              onClick={copyToClipboard}
              onKeyPress={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  copyToClipboard();
                }
              }}
            >
              <div className="payment-contact-email">finance@edgeonline.co</div>
              <img src={CopyIcon} alt="copy-icon" />
            </div>
          </div>
        </Popover>
      </>
    );
  };

  // useEffect(() => {
  //   let config = {};

  //   if (linkToken != undefined && linkToken != null) {
  //     config = {
  //       token: linkToken.link_token,
  //       onSuccess,
  //     };
  //   }
  // }, [linkToken]);

  useEffect(() => {
    getLinkToken();
  }, [bankAccountsDetails]);

  useEffect(() => {
    const userAdditionalInfo = JSON.parse(sessionStorage.getItem("userAdditionalInfo"));
    if (addBankAccountInfo?.ok) {
      dispatch(
        CustomerBankInfo({
          request: `${baseUrl}/customer/${userAdditionalInfo?.customerId}/bankInfo`,
        })
      );
    }
  }, [addBankAccountInfo]);

  useEffect(() => {
    dispatch(ResetAddCustomerBankInfo({}));
    dispatch(
      CustomerBankInfo({
        request: `${baseUrl}/customer/${loggedInUser?.customerId}/bankInfo`,
      })
    );
  }, [loggedInUser]);

  useEffect(() => {
    if (state?.AddPaymentMethodByDefault) {
      open();
    }
  }, [state]);

  return loggedInUserType === "customer" ? (
    <>
      <Helmet>
        <title>Edge | Payment Methods</title>
      </Helmet>
      {(loadingAddBankAccountInfo || loadingBankAccountInfo || loadingplatformConfig) && <PageLoader />}
      <Grid direction="column" container>
        <Grid container rowSpacing={3} sx={{ background: "white", marginTop: "5px" }}>
          <PageTitle
            page={{
              title: "Payment Methods",
              linkName: "Payment Methods",
              link: "",
            }}
          />
        </Grid>
        <Grid
          sx={{
            maxWidth: "1076px",
            width: "100%",
            margin: "0px auto",
            padding: "40px 0px 25px 0",
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Grid item container direction="column">
            <Grid item>
              {/* {isValidResponse(platformConfig) && platformConfig?.payload?.MANUAL_ACH_ENABLED === "1" && ( */}

              {bankAccountsDetails?.routingNumber && !isCreditCard ? (
                <Grid
                  sx={{
                    width: "1077px",
                    margin: "0px auto",
                    // display: "flex",
                    alignItems: "center",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "24px",
                    position: "relative",
                    padding: "16px 24px",
                  }}
                >
                  <div className="payment-methods-heading">Your payment methods</div>
                  {displayInfoIcon()}
                  <div className="payment-details-container">
                    <div className="flex flex-col ">
                      <div className="flex justify-between">
                        <div className="flex gap-05 ach-label-wrapper">
                          <img src={BankIcon} alt="credit card icon" />
                          <div className="payment-method-ach-label">ACH</div>
                        </div>

                        <div
                          className="payment-method-edit cursor-pointer"
                          onClick={() => {
                            setOpenEch(true);
                            setEditAch(true);
                          }}
                          role="button"
                          tabIndex={0}
                          onKeyPress={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              setOpenEch(true);
                              setEditAch(true);
                            }
                          }}
                        >
                          Edit
                        </div>
                      </div>

                      <div className="mt-1 flex gap-2">
                        <div className="flex flex-col">
                          <div className="ach-detail-label">Account number</div>
                          <div className="ach-detail-value">**{bankAccountsDetails?.last4}</div>
                        </div>

                        <div className="flex flex-col">
                          <div className="ach-detail-label">Routing number</div>
                          <div className="ach-detail-value">**{bankAccountsDetails?.routingNumber?.slice(-4)}</div>
                        </div>
                      </div>
                    </div>

                    {bankAccountsDetails?.lastPayrollId && (
                      <div className="">
                        <Divider className="pt-1" />

                        <div
                          className="flex justify-between cursor-pointer"
                          onClick={() => {
                            navigate(`/main/payroll/detail/${bankAccountsDetails?.lastPayrollId}`);
                          }}
                          onKeyPress={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              navigate(`/main/payroll/detail/${bankAccountsDetails?.lastPayrollId}`);
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="pt-1">
                            <span className="last-payroll-month">
                              {getMonthYearFromDate(bankAccountsDetails?.lastPayrollDate)} Payroll:{" "}
                            </span>
                            <span className="last-payroll-amount"> ${bankAccountsDetails?.lastPayrollAmount}</span>
                          </div>

                          <div
                            className="payment-method-status-container"
                            style={{
                              background: getBackgroundColor(lastPayrollFailed, lastPayrollStatus),
                            }}
                          >
                            <span
                              className="payment-method-status"
                              style={{
                                color: getColor(lastPayrollFailed, lastPayrollStatus),
                              }}
                            >
                              {getStatusText(lastPayrollFailed, lastPayrollStatus)}
                              <img
                                src={ChevronIcon}
                                alt="right arrow icon"
                                className="chevron-right-payment"
                                style={{
                                  filter: getFilter(lastPayrollFailed, lastPayrollStatus),
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </Grid>
              ) : (
                <>
                  {isCreditCard ? (
                    <>
                      <Grid
                        sx={{
                          width: "1077px",
                          margin: "0px auto",
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "24px",
                          position: "relative",
                        }}
                      >
                        <Grid
                          sx={{
                            marginTop: "12px",
                            padding: "16px 24px",
                            width: "340px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <div className="payment-methods-heading">Your payment methods</div>

                          <div className="credit-card-container">
                            <img src={CreditCardIcon} alt="credit card icon" />
                            <span className="credit-card-label">Credit/Debit Card</span>
                          </div>
                        </Grid>

                        {displayInfoIcon()}
                      </Grid>

                      <div className="ach-recommendation-container">
                        <div className="ach-recommendation-heading">We recommend using ACH Direct Debit</div>
                        <div className="ach-recommendation-desc">
                          It looks like you’re using Credit/Debit Card as your payment method. We recommend using ACH
                          Direct Debit for more seamless, reliable payments.
                        </div>

                        <div className="flex gap-1">
                          <div className="flex gap-05 pt-05">
                            <img src={TickIcon} alt="tick icon" />
                            <span className="ach-recommendation-benefits-label">No surcharge</span>
                          </div>

                          <div className="flex gap-05 pt-05">
                            <img src={TickIcon} alt="tick icon" />
                            <span className="ach-recommendation-benefits-label">Faster payments</span>
                          </div>

                          <div className="flex gap-05 pt-05">
                            <img src={TickIcon} alt="tick icon" />
                            <span className="ach-recommendation-benefits-label">Safe & secure</span>
                          </div>
                        </div>

                        <Button
                          type="button"
                          variant="primary"
                          size="md"
                          id="add-accounts-btn"
                          className="mt-125 mb-1 mt-15"
                          style={{ background: "var(--color-primitives-primary-600)", width: "250px" }}
                          onClick={() => setOpenEch(true)}
                        >
                          Update to ACH Direct Debit
                        </Button>
                      </div>
                    </>
                  ) : (
                    <Grid
                      sx={{
                        width: "1077px",
                        margin: "0px auto",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#FFFFFF",
                        borderRadius: "24px",
                        position: "relative",
                      }}
                    >
                      <Grid
                        sx={{
                          marginTop: "12px",
                          padding: "16px 24px",
                          width: "472px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="payment-method-heading">Add Payment Method</div>
                        <div className="payment-method-desc mt-05">
                          It seems you haven&apos;t added any payment methods yet. To proceed with transactions
                          smoothly, you can securely add your payment details here.
                        </div>
                        <br />
                        <div className="payment-method-desc">
                          Your information will be encrypted and securely stored for future transactions, providing you
                          with peace of mind.
                        </div>

                        <Button
                          type="button"
                          variant="primary"
                          id="add-accounts-btn"
                          className="mt-125 mb-1"
                          role="button"
                          tabIndex={0}
                          style={{
                            background: "var(--color-primitives-primary-600)",
                            width: "250px",
                            padding: "12px 20px",
                            gap: "12px",
                          }}
                          onClick={() => setOpenEch(true)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              setOpenEch(true);
                            }
                          }}
                        >
                          Add ACH Details
                        </Button>

                        <div
                          className="connect-plaid-btn  cursor-pointer"
                          onClick={() => open()}
                          onKeyPress={(event) => {
                            if (event.key === "Enter" || event.key === " ") {
                              open();
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          Or connect your bank via Plaid
                        </div>
                      </Grid>

                      <img
                        className="payment-illustration"
                        src={platfromRebrandPhase1 ? `${s3ImagesBaseURL}/payment-method.svg` : PaymentIllustration}
                        alt="payment-illustration"
                      />
                      {displayInfoIcon()}
                    </Grid>
                  )}
                  {/* )} */}
                </>
              )}
            </Grid>
          </Grid>
        </Grid>

        {plaidClosed && (
          <div className="plaid-link-closed-container plaid-link-closed-wrapper">
            <span className="plaid-link-close-desc">Can’t find your bank on Plaid? </span>
            <span
              className="plaid-link-close-btn cursor-pointer"
              onClick={() => setOpenEch(true)}
              onKeyPress={(event) => {
                if (event.key === "Enter" || event.key === " ") {
                  setOpenEch(true);
                }
              }}
              role="button"
              tabIndex={0}
            >
              {" "}
              Add ACH details manually.
            </span>
          </div>
        )}

        <AddEchInfo
          setOpenEch={setOpenEch}
          editAch={editAch}
          hidePlaidBanner={() => setPlaidClosed(false)}
          open={openEch}
          Anchor={handleEchClose}
        />

        <DeleteBankAcc
          setOpenDeleteAcc={() => setOpenDeleteAcc(false)}
          open={openDeleteAcc}
          Anchor={handleDeleteAccClose}
        />
      </Grid>
    </>
  ) : null;
}

export default PaymentMethods;
