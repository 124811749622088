import React, { useMemo, useState } from "react";

import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import jwt from "jwt-decode";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Box, Button, Grid, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { getError } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";

import { baseUrl } from "util/APIBaseUrl";
import postRequest from "util/APIHelperPost";

import { ForgotUserPassword } from "store/actions/ForgotPassword";

function ResendLink() {
  const [resendEmail, setResendEmail] = useState({});
  const dispatch = useDispatch();
  const { platfromRebrandPhase1 } = useFlags();

  const navigate = useNavigate();

  const forgotPasswordInfo = useSelector((state) => state.ForgotPassword.forgotPasswordInfo);
  const loadingForgotPasswordInfo = useSelector((state) => state.ForgotPassword.loadingForgotPasswordInfo);

  const queryParams = new URLSearchParams(window.location.search);
  const event = queryParams.get("event");
  const token = queryParams.get("token");
  const tokenStatus = queryParams.get("tokenStatus");

  const decodeToken = useMemo(() => {
    if (token) {
      return jwt(token);
    }
    return {};
  }, [token]);

  const handleResendEmail = async () => {
    if (decodeToken?.emailType !== "PASSWORD_CHANGE") {
      try {
        const body = JSON.stringify({
          email: decodeToken?.email,
          password: decodeToken?.password,
          emailType: "PASSWORD_CHANGE",
          eventType: event,
          type: decodeToken?.userType,
          token,
        });

        const response = await postRequest(`${baseUrl}/auth/resend`, body, true);

        setResendEmail(response);
      } catch (error) {
        console.error(error);
      }
    } else {
      dispatch(
        ForgotUserPassword({
          email: decodeToken?.email,
          type: decodeToken?.userType,
        })
      );
    }
  };

  function getMessage(forgotPasswordInfoParam, resendEmailParam, tokenStatusParam) {
    if (forgotPasswordInfoParam?.ok || resendEmailParam?.ok) {
      return <>Great! The link was sent to your registered email ID</>;
    }
    if (tokenStatusParam === "used") {
      return "Oops! The link you clicked on is already used";
    }
    if (tokenStatusParam === "expired") {
      return (
        <>
          Hi there! Seems like <br /> your link has expired
        </>
      );
    }
    return "Oops! The link you clicked on is invalid";
  }

  return (
    <>
      {loadingForgotPasswordInfo && <PageLoader />}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        sx={{ backgroundColor: "#FFFFFF", overflowY: "auto" }}
      >
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item container sx={{ maxWidth: "330px" }}>
            <Grid item container direction="column" rowSpacing={4}>
              <Grid item sx={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  flexDirection="row"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ArrowBackIcon textAlign="left" fontSize="12px" />
                  <Typography
                    fontSize="13px"
                    sx={{
                      fontFamily: "Poppins-Medium !important",
                      paddingLeft: "5px",
                    }}
                  >
                    Back
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                {platfromRebrandPhase1 ? (
                  <EdgeLogo size="lg" state="dark" />
                ) : (
                  <Box
                    component="img"
                    sx={{
                      height: "auto",
                      maxWidth: "100%",
                    }}
                    alt="edge"
                    src="assets/Images/edge-logo.svg"
                  />
                )}
              </Grid>
              <Grid item sx={{ mt: "35px" }}>
                <Typography
                  sx={{
                    fontFamily: "Poppins-Bold !important",
                    color: "#000000",
                    fontSize: "30px",
                    fontWeight: "600",
                    lineHeight: "44px",
                    marginTop: "20px",
                    "@media screen and (min-width: 912px)": {
                      marginTop: "0px",
                    },
                  }}
                >
                  {getMessage(forgotPasswordInfo, resendEmail, tokenStatus)}
                </Typography>
              </Grid>

              {forgotPasswordInfo?.ok == false || resendEmail?.ok == false ? (
                <Grid item sx={{ mt: "0px !important", pt: "0px !important" }}>
                  <Typography
                    variant="h3Lite"
                    sx={{
                      fontFamily: "Poppins-Regular !important",
                      fontWeight: "400",
                      lineHeight: "16px",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    {forgotPasswordInfo?.ok == false || resendEmail?.ok == false
                      ? getError(forgotPasswordInfo?.payload?.error || resendEmail?.payload?.error)
                      : null}
                  </Typography>
                </Grid>
              ) : null}

              {forgotPasswordInfo?.ok || resendEmail?.ok ? null : (
                <Grid item>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleResendEmail}
                    sx={{
                      backgroundColor: "var(--color-primitives-primary-600)",
                      height: "54px",
                      fontFamily: "Poppins-Regular !important",
                      fontWeight: "500",
                      lineHeight: "20px",
                      borderRadius: "99px",
                      fontSize: "16px",
                      color: "white",
                      boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                    }}
                  >
                    Request a new link
                  </Button>
                </Grid>
              )}

              <Grid item>
                <Typography
                  variant="h3Lite"
                  sx={{
                    fontFamily: "Poppins-Regular !important",
                    fontWeight: "400",
                    lineHeight: "16px",
                    fontSize: "12px",
                    color: "#000000",
                  }}
                >
                  {forgotPasswordInfo?.ok == false || resendEmail?.ok == false ? (
                    <>Did not receive the email? Wait for a couple of minutes. It might be on it’s way!</>
                  ) : (
                    <>A new link to reset your password will be sent to your registered email address</>
                  )}
                </Typography>
              </Grid>

              <Grid item textAlign="center" />
              <Grid item textAlign="center">
                <Typography variant="h3Lite" fontSize="9px">
                  {process.env.REACT_APP_NAME}
                  {` ${process.env.REACT_APP_VERSION}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          backgroundColor="var(--color-primitives-primary-600)"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              maxWidth: "528px",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "var(--color-primitives-primary-600)",
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                maxWidth: "400px",
                width: "100%",
              },
            }}
            alt="The house from the offer."
            src="assets/Images/login-image.png"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default ResendLink;
