import { createTheme } from "@mui/material/styles";

// eslint-disable-next-line import/prefer-default-export
export const ThemeRebrand = createTheme({
  palette: {
    primary: {
      main: "#8139D0",
    },

    primary1Color: {
      main: "#8139D0",
    },

    primaryAlt: {},

    secondary: {
      main: "#8139D0",
    },

    warning: {
      main: "#F64A14",
    },

    pending: {
      main: "#DAE6F8",
    },

    success: {
      main: "#38E25D",
    },

    info: {
      main: "#cccccc",
    },

    muted: {
      main: "#a5a5a5",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },

  typography: {
    useNextVariants: true,

    button: {
      textTransform: "none",
    },

    h1: {
      fontFamily: "Poppins-Bold",
      letterSpacing: "0.02em",
      fontSize: "26px",
      fontWeight: "bold",
      color: "#151968",
    },

    h2: {
      fontSize: "14px",

      fontFamily: "Poppins-Regular",
      color: "#151968",
    },

    h3: {
      fontSize: "13px",
      fontFamily: "Poppins-Medium",
      color: "#151968",
    },

    h3Lite: {
      fontSize: "14px",
      fontFamily: "Poppins-Light",
      color: "grey",
    },

    h4: {
      fontSize: "22px",
      fontFamily: "Poppins-Bold",
      fontWeight: "bold",
      color: "#151968",
    },

    h5: {
      fontSize: "13px",

      fontFamily: "Poppins-SemiBold",
      color: "#151968",
    },

    h6: {
      fontFamily: "Poppins-Regular",
      fontSize: "12px",
      color: "#151968",
    },

    h6Bold: {
      fontFamily: "Poppins-SemiBold",
      fontSize: "14px",
      color: "#151968",
    },

    h6Sidebar: {
      fontFamily: "Poppins-Regular",
      fontSize: "13px",
      fontWeight: "bold",
      color: "#151968",
    },

    paragraph: {
      fontSize: "11px",

      fontFamily: "Poppins-Medium",
    },

    subText: {
      color: "#c1c1c1",
      fontSize: "12px",
      fontFamily: "Poppins-Regular",
    },

    input: {
      fontFamily: "Poppins-Medium",
      fontSize: "12px",
      color: "#8386A2",
    },
  },

  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: "46px !important",
          height: "46px !important",
          paddingLeft: "44px !important",
          paddingRight: "60px !important",
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "5px !important",
        },
        elevation1: {
          boxShadow: "rgba(0, 0, 0, 0.02) 0px 3px 4px !important",
        },
        elevation3: {
          boxShadow:
            "rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px",
        },
      },
    },

    MuiCollapse: {
      styleOverrides: {
        wrapperInner: {
          backgroundColor: "white",
          zIndex: "1000",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: 0,
          },
          minHeight: 0,
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 10,
          height: 42,
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#1161D5 #fffff",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(0, 0,0, 0.0)",
            width: "10px",
            height: "0",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 2,

            backgroundColor: "#F4F4F4",
            minHeight: 24,
            maxWidth: 3,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
        },
      },
    },
  },
});
