import * as React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import { asyncWithLDProvider, useFlags } from "launchdarkly-react-client-sdk";
import * as Sentry from "@sentry/react";

import { ThemeProvider } from "@mui/material/styles";

import App from "./app";

import { Theme } from "./Theme";
import { ThemeRebrand } from "./ThemeRebrand";
import configureStore from "./store";
import getUserContext from "util/GetUserContext";

import "./index.css";

const store = configureStore();
const appHostName = window.location.hostname.split(".")[0];

let environment = "";
let clientSideIDLaunchDarkly = "";

if (appHostName === "app") {
  environment = "prod";
  clientSideIDLaunchDarkly = "66560e84484b7b0fef5cbb73";
} else if (appHostName === "uat") {
  environment = "uat";
  clientSideIDLaunchDarkly = "66c4781bc1e1e41080a55b1e";
} else {
  environment = "qa";
  clientSideIDLaunchDarkly = "66c477f03f8d3a0fa9cef9fb";
}

if (window.location.hostname !== "localhost") {
  Sentry.init({
    dsn: "https://a84400b0b0c3585291fe7c646486031a@o4506113118633984.ingest.us.sentry.io/4506828857278464",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    environment,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["*"],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: clientSideIDLaunchDarkly,
    context: getUserContext(),
  });

  const root = ReactDOM.createRoot(document.getElementById("root"));

  function Root() {
    const { platfromRebrandPhase1 } = useFlags();

    useEffect(() => {
      const colorLevels = ["900", "800", "700", "600", "500", "400", "300", "200", "100", "50"];

      colorLevels.forEach((level) => {
        if (platfromRebrandPhase1) {
          const purpleColor = getComputedStyle(document.documentElement).getPropertyValue(
            `--color-brand-primary-purple-${level}`
          );
          document.documentElement.style.setProperty(`--color-primitives-primary-${level}`, purpleColor);
        } else {
          const blueColor = getComputedStyle(document.documentElement).getPropertyValue(
            `--color-brand-primary-blue-${level}`
          );
          document.documentElement.style.setProperty(`--color-primitives-primary-${level}`, blueColor);
        }
      });

      if (platfromRebrandPhase1) {
        const purpleColor = getComputedStyle(document.documentElement).getPropertyValue(
          "--color-brand-primary-purple-600"
        );
        document.documentElement.style.setProperty("--temp-color-primitives-primary-800", purpleColor);
      } else {
        const blueColor = getComputedStyle(document.documentElement).getPropertyValue("--color-brand-primary-blue-800");
        document.documentElement.style.setProperty("--temp-color-primitives-primary-800", blueColor);
      }
    }, [platfromRebrandPhase1]);

    return (
      <Provider store={store}>
        <ThemeProvider theme={platfromRebrandPhase1 ? ThemeRebrand : Theme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    );
  }

  root.render(
    <LDProvider>
      <Root />
    </LDProvider>
  );
})();
