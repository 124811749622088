import React, { createRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Avatar } from "@mui/material";

import { getValue } from "components/Screens/CommonUtils";
import PageLoader from "components/common/PageLoader";
import PersonalInfo from "components/Screens/Workforce/UserDetails/NewPersonalInfo/PersonalInfo";
import LastActive from "components/common/LastActive";
import EmploymentInformation from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo";
import EditPersonalInfoModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EditPersonalInfoModal";
import ImageCropperModal from "components/common/ImageCropperModal";
import EmployeeAssignmentBanner from "components/Widgets/BambooHRTabs/SalaryDetails/EmployeeAssignmentBanner";
import CustomModal from "components/common/Modal";
import AddEditSalaryDetails from "components/Widgets/BambooHRTabs/SalaryDetails/AddEditSalaryDetails";

import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";

import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";

import { uploadWorkforcePhoto } from "services/Admin/Dashboard";
import { getEmployeeSalary, updateEmployeeSalary } from "services/BambooHrTabs/salarydetails";

import "./PersonalInfo.scss";

function PersonalInformationTalent({ employeeIDParams, employeeDetails, fetchEmployeeDetailsByIDV2 }) {
  const flags = useFlags();

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const employeeDetailsLoading = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const [showImageCropperModal, setShowImageCropperModal] = useState(false);
  const [showEditPersonalInfoModal, setShowEditPersonalInfoModal] = useState(false);
  const [imageSrc, setImageSrc] = useState();
  const [croppedUserImage, setCroppedUserImage] = useState();
  const [showSalaryModal, setShowSalaryModal] = useState(false);
  const [salaryData, setSalaryData] = useState(null);
  const [isSalaryLoading, setIsSalaryLoading] = useState(false);
  const [showAssignmentBanner, setShowAssignmentBanner] = useState(false);

  const [isFileUploadError, setIsFileUploadError] = useState(false);

  const photoInput = createRef();

  useEffect(() => {
    if (
      !employeeDetailsLoading &&
      employeeDetails?.salaryStatus?.toLowerCase() !== "complete" &&
      (employeeDetails?.status?.toLowerCase() === "assigned" || employeeDetails?.status?.toLowerCase() === "pending")
    ) {
      setShowAssignmentBanner(true);
    } else {
      setShowAssignmentBanner(false);
    }
  }, [employeeDetailsLoading, employeeDetails]);

  const uploadProfilePic = () => {
    photoInput.current.click();
  };

  const fetchSalaryData = async () => {
    try {
      const data = await getEmployeeSalary(employeeIDParams);

      setSalaryData(data?.payload && Object.keys(data.payload).length > 0 ? data.payload : null);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSalaryLoading(false);
    }
  };

  const handleActionButtonClick = async () => {
    await fetchSalaryData();
    setShowSalaryModal(true);
  };

  const handleSaveSalary = async (updatedData) => {
    setIsSalaryLoading(true);
    try {
      setShowSalaryModal(false);
      await updateEmployeeSalary(employeeIDParams, updatedData);
      await fetchSalaryData();
      await fetchEmployeeDetailsByIDV2();
      setShowAssignmentBanner(false);
    } catch (err) {
      console.error(err);
    } finally {
      setIsSalaryLoading(false);
    }
  };

  const handleCancelSalary = () => {
    setShowSalaryModal(false);
  };

  const {
    lastActive,
    lastActiveEmail,
    employeeId,
    firstName,
    middleName,
    preferredName,
    lastName,
    country,
    cnic_dni_cex: nationalID,
    jobTitle,
    gender,
    maritalStatus,
    dateOfBirth,
    allowedForPayoneer,
  } = getEmployeeDetails?.payload || {};

  const { hrpoc } = employeeDetails;

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.addEventListener(
        "load",
        () => {
          resolve(reader.result);
        },
        false
      );
      reader.readAsDataURL(file);
    });
  }

  const handleImageChange = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    e.target.value = "";

    if (file.size / 1024 / 1024 > 2) setIsFileUploadError(true);
    else setIsFileUploadError(false);

    const imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);

    setShowImageCropperModal(true);
  };

  const handleSave = async (param) => {
    const formData = new FormData();
    const imageDataUrl = await readFile(param);
    setCroppedUserImage(imageDataUrl);

    formData?.append("file", param);
    setShowImageCropperModal(false);
    uploadWorkforcePhoto({
      request: `/employee/${employeeId}/logo`,
      formData,
    });
  };

  return (
    <div>
      {(employeeDetailsLoading || isSalaryLoading) && <PageLoader />}

      <EditPersonalInfoModal
        open={showEditPersonalInfoModal}
        closeModal={() => setShowEditPersonalInfoModal(false)}
        flags={flags}
        fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
        initialPersonalDataWorkforce={{
          employeeId,
          firstName,
          middleName,
          preferredName,
          lastName,
          employeeSuccessManager: hrpoc?.firstName ? `${hrpoc?.firstName} ${hrpoc?.lastName}` : "",
          country,
          jobTitle,
          nationalID,
          gender,
          maritalStatus,
          dateOfBirth,
          allowedForPayoneer,
        }}
      />

      <ImageCropperModal
        open={showImageCropperModal}
        onClose={() => setShowImageCropperModal(false)}
        imageSrc={imageSrc}
        onSave={handleSave}
        isFileUploadError={isFileUploadError}
        errorMessage="The file you've selected is too large. Please upload an image file that is 2MB or smaller."
      />

      <CustomModal
        open={showSalaryModal}
        title="Add Salary"
        sx={{ maxWidth: "652px !important", width: "652px !important", padding: "24px !important" }}
      >
        <AddEditSalaryDetails
          onSave={handleSaveSalary}
          onCancel={handleCancelSalary}
          initialData={salaryData || {}}
          employeeStatus={getEmployeeDetails?.payload?.status}
        />
      </CustomModal>

      {showAssignmentBanner && (
        <EmployeeAssignmentBanner
          bannerText="This employee is not yet visible to the customer or on their payroll due to missing salary details. Add salary details now to start billing the customer."
          actionButtonLabel="Add Salary Details"
          handleActionButtonClick={handleActionButtonClick}
          isOverviewTab
        />
      )}

      <div className="workforce-overview-container">
        <div className="employee-info-wrapper">
          <div className="personal-info-container">
            <PersonalInfo flags={flags} showEditPersonalInfoModal={() => setShowEditPersonalInfoModal(true)} />
          </div>

          <div>
            <EmploymentInformation
              fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
              employeeDetails={employeeDetails}
              initialPersonalDataEmployment={{
                employeeId,
              }}
            />
          </div>
        </div>

        <div className="avatar-container">
          <div className="avatar-wrapper">
            <div className="relative cursor-pointer" onClick={uploadProfilePic}>
              <Avatar
                variant="rounded"
                className="employee-overview-avatar"
                imgProps={{ crossOrigin: "anonymous" }}
                src={
                  croppedUserImage ??
                  `${baseUrl}/employee/${getEmployeeDetails?.payload?.employeeId}/logo?id=${getEmployeeDetails?.payload?.pictureVersion}`
                }
              >
                <img alt="placeholder-avatar" src={`${s3ImagesBaseURL}/placeholder-avatar.svg`} />
              </Avatar>

              <div className="camera-image-upload-icon">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageChange}
                  ref={photoInput}
                  style={{ display: "none" }}
                />
                <CameraAltOutlinedIcon className="color-icon-primary" />
              </div>
            </div>

            <div className="heading-h2-semibold text-center avatar-emp-name">
              {`${getValue(getEmployeeDetails.payload?.firstName)} ${getEmployeeDetails.payload?.middleName ? getValue(getEmployeeDetails.payload?.middleName) : ""} ${getValue(getEmployeeDetails.payload?.lastName)}`}
            </div>

            <LastActive {...{ lastActive, lastActiveEmail }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalInformationTalent;
