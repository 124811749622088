import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";

import { Button, Chip, Divider, Stack, Typography } from "@mui/material";
import { differenceInDays } from "date-fns";

import PendingTimeIcon from "assets/images/pending-timer.svg";
import LeftArrow from "assets/images/left-arrow.svg";
import AvatarImage from "assets/images/fallback-image.svg";
import ProfileImage from "assets/images/profile.svg";
import { ReactComponent as CheckMarksIcon } from "assets/images/check-marks.svg";

import Comment from "components/Screens/Timeoff/ManageTimeoff/Comment/Customer";
import LeaveType from "../../Common/LeaveType";
import Documents from "./Documents";
import { convertDate, getWorkingDuration } from "components/Screens/CommonUtils";
import EmployeeAvatar from "components/Screens/Timeoff/TimeoffAdmin/EmployeeTimeOffDetails/EmployeeAvatar";
import { getEmployeeDocuments } from "components/Screens/Timeoff/TimeoffAdmin/TimeOffAdminServices/EmployeeTimeOffDetailsService";
import PageLoader from "components/common/PageLoader";
import EditByHrStatus from "components/Screens/Timeoff/ManageTimeoff/Common/EditByHrStatus";
import Modal from "./LeaveReplacementModal";

import { usePermissions } from "components/Hook";
import { USER_TYPES, LEAVE_STATUSES } from "constants/index";

import { GET_LEAVE_REQUESTS_SUCCESS } from "store/constants/Timeoff";

import { approveOrRejectLeaveRequest } from "services/useTimeoffService";
import { showSnackbar } from "store/actions/Utility";
import { FetchLeaveRequests } from "store/actions/Timeoff/LeaveRequests";

import { baseUrl } from "util/APIBaseUrl";

import "./LeaveCard.css";

function LeaveCard({
  data = {},
  admin,
  showProfileSection,
  setLeaveCardIndexToZero = () => {},
  fetchEmployeePendingLeaves = () => {},
  runAnimationForLeaveCard = () => {},
  navigateToEmployeeProfile,
  onSuccessApproveLeave,
  leaveDetailsLoader,
  setShowReplacementSuccessModal,
}) {
  const {
    employeeName,
    status,
    jobTitle,
    leaveType,
    dateTo,
    dateFrom,
    noOfLeaves,
    leaveStatus,
    createdAt,
    description,
    customerId,
    country,
    requestId,
    approvedReason,
    rejectedReason,
    employeeId,
    documentsAttached,
    pictureVersion,
    hrApproved,
    hrEdit,
    originalLeaveType,
    enableReplacement,
    replacementRequested,
  } = data;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const leaveRequests = useSelector((state) => state?.TimeoffLeaveRequests || []);
  const loadingLeaveRequests = useSelector((state) => state?.TimeoffLeaveRequests?.loadingLeaveRequests);

  const [reason, setReason] = useState("");
  const [collapsed, setCollapsed] = useState(true);
  const [employeeDocumentsList, setEmployeeDocumentsList] = useState([]);
  const [upadtingLeaveStatus, setUpadtingLeaveStatus] = useState({ state: false, showSnackbar: false });
  const { loggedInUserType } = usePermissions();
  const [empImageSrc, setEmpImageSrc] = useState(AvatarImage);
  const [customerImageSrc, setCustomerImageSrc] = useState(AvatarImage);
  const [showModal, setShowModal] = useState(false);
  const [requestReplacement, setRequestReplacement] = useState(false);

  const pending = status === LEAVE_STATUSES.PENDING;
  const scheduledStatus = leaveStatus === LEAVE_STATUSES.SCHEDULED;

  const getAlertMessage = (action) => {
    if (loggedInUserType === USER_TYPES.EDGE) {
      if (action === "Approved") {
        return "The scheduled leave has been approved as sick leave by you.";
      }

      return "The scheduled leave has been approved as Annual Leave";
    }
    if (action === "Approved") {
      return `You just Accepted ${employeeName}'s time-off request. The timeoff is scheduled!`;
    }

    return `You just Rejected ${employeeName}'s time-off request. The timeoff is rejected!`;
  };

  const handleAcceptRejectPendingLeave = async (action, replacementData = {}) => {
    setUpadtingLeaveStatus({
      state: true,
      showSnackbar: false,
    });

    try {
      const message = getAlertMessage(action);
      const res = await approveOrRejectLeaveRequest({
        customerId,
        requestId,
        action,
        reason,
        loggedInUserType,
        replacementData,
        requestReplacement,
      });

      if (res?.payload?.success) {
        if (onSuccessApproveLeave && loggedInUserType === USER_TYPES.EDGE) onSuccessApproveLeave(requestId);
        else {
          const pendingLeaveReqs = leaveRequests?.leaveRequests?.pending?.filter((req) => req.requestId !== requestId);

          const leaveReqs = {
            ...leaveRequests.leaveRequests,
            pending: pendingLeaveReqs,
          };

          dispatch({
            type: GET_LEAVE_REQUESTS_SUCCESS,
            payload: { payload: { data: leaveReqs } },
          });
          dispatch(FetchLeaveRequests({}));
          fetchEmployeePendingLeaves();
        }

        setReason("");

        setUpadtingLeaveStatus({
          state: false,
          showSnackbar: true,
          type: action === "Approved" ? "success" : "failure",
          snackbarMessage: message,
        });
      } else {
        setUpadtingLeaveStatus({
          state: false,
          showSnackbar: true,
          type: "failure",
          snackbarMessage: res?.payload?.message,
        });
      }

      setLeaveCardIndexToZero();
    } catch (error) {
      setUpadtingLeaveStatus({
        state: false,
        showSnackbar: true,
        type: "failure",
        snackbarMessage: "Something went wrong. Pls try again!",
      });
    }
  };

  const handleModalData = (formData) => {
    handleAcceptRejectPendingLeave("Approved", formData);
  };

  const fetchEmployeeDocumentsList = async () => {
    try {
      const request = {
        edge: `admin/leave/document/${requestId}`,
      };

      const response = await getEmployeeDocuments(request[loggedInUserType]);
      if (response?.success) {
        setEmployeeDocumentsList(response?.data);
      }
    } catch (error) {
      console.error("here is error:", error);
    }
  };

  const getScheduleTextClass = () => {
    if (loggedInUserType === USER_TYPES.EDGE) {
      return "scheduled-timeoff-details-text-admin";
    }
    return "scheduled-timeoff-details-text";
  };

  const navigateToProfile = () => {
    if (loggedInUserType === USER_TYPES.EDGE) {
      return navigateToEmployeeProfile(employeeId);
    }
    return navigate(`/main/details/${employeeId}`, { state: { selectedTab: 5 } });
  };

  const handleModal = () => {
    setShowModal(true);
  };

  const handleRequestReplacementModal = () => {
    setShowModal(true);
    setRequestReplacement(true);
  };

  useEffect(() => {
    if (documentsAttached && requestId) {
      fetchEmployeeDocumentsList();
    }
  }, [documentsAttached, requestId]);

  useEffect(() => {
    if (upadtingLeaveStatus?.showSnackbar)
      dispatch(
        showSnackbar({
          dispatch,
          type: upadtingLeaveStatus?.type,
          message: upadtingLeaveStatus?.snackbarMessage,
        })
      );
  }, [upadtingLeaveStatus]);

  useEffect(() => {
    if (employeeId && pictureVersion) {
      const empImageUrl = `${baseUrl}/employee/${employeeId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setEmpImageSrc(empImageUrl);
    } else {
      setEmpImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);

  useEffect(() => {
    if (customerId && pictureVersion) {
      const customerImageUrl = `${baseUrl}/customer/${customerId}/logo?id=${pictureVersion}&variant=thumbnail`;
      setCustomerImageSrc(customerImageUrl);
    } else {
      setCustomerImageSrc(AvatarImage);
    }
  }, [employeeId, pictureVersion]);

  if (data)
    return (
      <div
        className={
          pending || !collapsed || admin ? "pending-leaves-card-container" : "pending-leaves-card-container-collapsed"
        }
      >
        <div className={pending || !collapsed || admin ? "flex flex-col" : " flex flex-row"}>
          <Modal
            open={showModal}
            employeeDetails={{ employeeName, dateFrom, dateTo }}
            closeModal={() => {
              setShowModal(false);
            }}
            setShowReplacementSuccessModal={setShowReplacementSuccessModal}
            onDataReceived={handleModalData}
          />
          {((loadingLeaveRequests && !leaveRequests) ||
            upadtingLeaveStatus.state ||
            (loggedInUserType === USER_TYPES.EDGE && leaveDetailsLoader)) && <PageLoader />}
          <div
            className={!pending && collapsed && !admin ? "flex flex-col justify-between w-45p" : "flex justify-between"}
          >
            {showProfileSection && (
              <>
                <div className="pending-leaves-date flex items-center">
                  <div className="pending-leaves-time-icon w-5">
                    <EmployeeAvatar
                      img={empImageSrc}
                      country={country}
                      size={{ width: "50px", height: "50px", borderRadius: "50%" }}
                    />
                  </div>

                  <div>
                    <div className="title" id="emp-name">
                      {employeeName}
                    </div>
                    <div className="title-sm neutral-60" id="emp-title">
                      {jobTitle}
                    </div>
                  </div>
                </div>
                {(pending || admin) && (
                  <img
                    className="pending-leaves-profile-icon cursor-pointer"
                    onClick={() => navigateToProfile()}
                    src={ProfileImage}
                    alt="profile"
                  />
                )}
              </>
            )}
            {!pending && !collapsed && (
              <FaChevronUp
                onClick={() => setCollapsed(!collapsed)}
                className="mr-2 mt-1 cursor-pointer text-xs font-thin"
              />
            )}
            {!pending && collapsed && !admin && (
              <div
                className="px-1 py-1 body-sm neutral-20 request-date"
                id="request-date"
              >{`Request date: ${convertDate(createdAt)} (${getWorkingDuration(createdAt)} ago)`}</div>
            )}
          </div>
          {(pending || admin) && <Divider light />}

          <div className={collapsed && !pending && !admin && "w-50p"}>
            <div className="pending-leaves-top-section">
              <div className={(pending || admin) && "flex"}>
                {pending && <img className="pending-leaves-time-icon" src={PendingTimeIcon} alt="pending time" />}

                <div className={!pending && "pl-1"}>
                  <div className="pending-leave-reason">
                    {originalLeaveType && originalLeaveType !== leaveType ? (
                      <>
                        <s>{`${originalLeaveType} ${noOfLeaves === 1 ? "leave" : "leaves"}`}</s>

                        <span style={{ paddingLeft: "8px" }}>
                          {`${leaveType} ${noOfLeaves === 1 ? "leave" : "leaves"}`}
                        </span>
                      </>
                    ) : (
                      `${leaveType} ${noOfLeaves === 1 ? "leave" : "leaves"}`
                    )}
                  </div>

                  <div className="pending-leave-duration space-x-1">
                    <div className="pending-leave-date" id="start-date">
                      {convertDate(dateFrom)}
                    </div>
                    {dateFrom !== dateTo && (
                      <>
                        <img className="pending-leave-arrow" src={LeftArrow} alt="left arrow" />
                        <div className="pending-leave-date" id="end-date">
                          {convertDate(dateTo)}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {(pending || admin) && <Divider light />}
            <div className={pending || !collapsed ? "pending-timeoff-details-wrapper" : "space-y-1 pb-1"}>
              <div className="pending-timeoff-details">
                <div
                  className={pending || !collapsed ? "pending-timeoff-details-text" : getScheduleTextClass()}
                  id="total-timeoff-used"
                >
                  Duration
                </div>
                <Chip
                  className="secondary-20 custom-chip"
                  size="small"
                  variant="outlined"
                  id="no-of-leaves"
                  label={`${noOfLeaves} ${noOfLeaves == 1 ? "leave" : "leaves"}`}
                />
              </div>

              <div className="pending-timeoff-details">
                <div className={pending || !collapsed ? "pending-timeoff-details-text" : getScheduleTextClass()}>
                  Notice Period
                </div>
                <Chip
                  className="secondary-20 custom-chip"
                  size="small"
                  variant="outlined"
                  id="notice-period"
                  label={`${differenceInDays(new Date(dateFrom), new Date(createdAt))} Days`}
                />
              </div>

              <div className="pending-timeoff-details">
                <div className={pending || !collapsed ? "pending-timeoff-details-text" : getScheduleTextClass()}>
                  Status
                </div>
                <LeaveType type={pending ? "Pending" : leaveStatus} />
              </div>

              {country === "Peru" && (leaveType === "Sick" || hrEdit || hrApproved) && (
                <div className="pending-timeoff-details">
                  <div className={pending || !collapsed ? "pending-timeoff-details-text" : getScheduleTextClass()}>
                    HR doc review
                  </div>
                  <EditByHrStatus
                    style={{ width: "fit-content !important" }}
                    hrEdit={hrEdit}
                    hrApproved={hrApproved}
                    leaveStatus={status}
                  />
                </div>
              )}
            </div>
          </div>

          {pending && <Divider light />}

          {(pending || !collapsed || loggedInUserType === USER_TYPES.EDGE) && (
            <>
              <div className="pending-timeoff-comments label-sm">Comments:</div>
              <Comment
                pending={pending}
                {...{
                  description,
                  employeeName,
                  reason,
                  setReason,
                  approvedReason,
                  rejectedReason,
                  country,
                  loggedInUserType,
                  empImageSrc,
                  customerImageSrc,
                }}
              />
            </>
          )}

          {!collapsed && scheduledStatus && replacementRequested && (
            <div className="flex items-center ml-1 mr-1 mt-075  py-05 px-1 rounded-lg bg-replacement">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M6.87571 10.1243L13.4583 3.54175L14.4598 4.54333L6.87571 12.1275L2.83325 8.085L3.83484 7.08341L6.87571 10.1243Z"
                  fill="#0ABA66"
                />
              </svg>
              <p className="primary-20 label-sm pl-05">Replacement Requested</p>
            </div>
          )}

          {!collapsed && scheduledStatus && enableReplacement && (
            <div className="flex items-center justify-between ml-1 mr-1 mt-075 py-05 pl-1 rounded-lg bg-replacement">
              <p className="primary-20 w-60p label-sm mr-025">
                We notice that this person is going to be away for 10 or more days. You can request a replacement for
                this role
              </p>
              <button
                type="button"
                className="button cursor-pointer w-40p replacement-request-button"
                onClick={handleRequestReplacementModal}
              >
                Request for replacement
              </button>
            </div>
          )}

          {admin && <Divider className="py-05" light />}
          {admin && documentsAttached && employeeDocumentsList?.length ? (
            <Documents documents={employeeDocumentsList} requestId={requestId} />
          ) : null}

          {/* {pending && !admin && ( */}
          {admin ? (
            <Stack direction="row" spacing="10px" sx={{ ml: "10px", mb: "9px", marginTop: "19px" }}>
              {hrApproved && (
                <Button
                  variant="contained"
                  disableElevation
                  sx={{
                    borderRadius: "42px",
                    // width: "204px",
                    height: "38px",
                    background: "#DDFCEF",
                    padding: "12px 24px 12px 24px",
                    color: "#007B23",
                    "&:hover": {
                      background: "#DDFCEF",
                    },
                  }}
                >
                  <Typography
                    variant="h6"
                    className="save-buttontext"
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#007B23",
                      fontFamily: "Poppins-Medium",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <CheckMarksIcon /> Approved as {leaveType} leave
                  </Typography>
                </Button>
              )}

              {hrEdit && !hrApproved && status === "Approved" && (
                <>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "42px",
                      width: "204px",
                      height: "38px",
                      background: "var(--temp-color-primitives-primary-800)",
                    }}
                    onClick={() => {
                      handleAcceptRejectPendingLeave("Approved");
                    }}
                  >
                    <Typography
                      variant="h6"
                      color="white"
                      className="save-buttontext"
                      style={{ fontSize: "14px", lineHeight: "20px" }}
                    >
                      Approve as sick leave{" "}
                    </Typography>
                  </Button>

                  <Button
                    variant="text"
                    sx={{
                      borderRadius: "24px",
                      height: "38px",
                      width: "291px",
                      border: "1px solid var(--color-primitives-primary-400)",
                    }}
                    onClick={() => handleAcceptRejectPendingLeave("Rejected")}
                  >
                    <Typography variant="h5" className="save-buttontext" color="#000000">
                      Approve as swapped, annual or unpaid
                    </Typography>
                  </Button>
                </>
              )}
            </Stack>
          ) : (
            pending && (
              <div className="button-container px-1 pt-1">
                {enableReplacement && (
                  <button type="button" className="button cursor-pointer replacement-button" onClick={handleModal}>
                    Accept with replacement
                  </button>
                )}
                <button
                  type="button"
                  className="button button-md cursor-pointer accept"
                  onClick={() => {
                    handleAcceptRejectPendingLeave("Approved");
                    runAnimationForLeaveCard();
                  }}
                >
                  Accept
                </button>
                <button
                  type="button"
                  className="button button-md cursor-pointer reject"
                  onClick={() => {
                    handleAcceptRejectPendingLeave("Rejected");
                    runAnimationForLeaveCard();
                  }}
                >
                  Reject
                </button>
              </div>
            )
          )}

          {pending || !collapsed || admin ? (
            <div className="px-1 py-1 body-sm neutral-20 request-date" id="request-date">
              Request date: {convertDate(createdAt)} ({getWorkingDuration(createdAt)} ago)
            </div>
          ) : (
            <div onClick={() => setCollapsed(!collapsed)} className="w-10p flex items-start justify-end cursor-pointer">
              <FaChevronDown className="mr-2 mt-1 text-xs font-thin" />
            </div>
          )}
        </div>
        {collapsed && scheduledStatus && enableReplacement && (
          <div className="flex items-center justify-between ml-1 mr-1 mb-05 py-05 pl-1 rounded-lg bg-replacement">
            <p className="primary-20 w-60p label-sm mr-025">
              We notice that this person is going to be away for 10 or more days. You can request a replacement for this
              role
            </p>
            <button
              type="button"
              className="button cursor-pointer w-40p replacement-request-button"
              onClick={handleRequestReplacementModal}
            >
              Request for replacement
            </button>
          </div>
        )}
        {collapsed && scheduledStatus && replacementRequested && (
          <div className="flex items-center ml-1 mr-1 mb-05 py-05 px-1 rounded-lg bg-replacement">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.87571 10.1243L13.4583 3.54175L14.4598 4.54333L6.87571 12.1275L2.83325 8.085L3.83484 7.08341L6.87571 10.1243Z"
                fill="#0ABA66"
              />
            </svg>
            <p className="primary-20 label-sm pl-05">Replacement Requested</p>
          </div>
        )}
      </div>
    );
}

export default LeaveCard;
