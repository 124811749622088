/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CardMedia, Divider, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import PageLoader from "components/common/PageLoader";
import Button from "components/Button/Button";
import EmployeePayrollCard from "components/EmployeePayrollCard/EmployeePayrollCard";
import BarChartV2 from "components/Graphs/BarChartV2";
import PayrollHistory from "./PayrollHistory";
import PageTitle from "components/Widgets/PageTitle";
import PageToolBar from "components/Widgets/PageToolBar";
import {
  convertDate,
  currencyFormat,
  getFieldValue,
  getMonthFromDate,
  getValue,
  handleRetryPayment,
  isEmptyObject,
} from "components/Screens/CommonUtils";

import { baseUrl, s3ImagesBaseURL } from "util/APIBaseUrl";
import { RetryPayment } from "store/actions/Payment/RetryPayment";
import { EmployeeInfo } from "store/actions/EmployeeInfo";
import { PayrollSummary } from "store/actions/PayrollSummary";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";

import { usePermissions } from "components/Hook";

import giftImage from "assets/images/gift.svg";

function Payroll(props) {
  const { selectPageHeading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { platfromRebrandPhase1 } = useFlags();

  const { loggedInUser, loggedInUserType } = usePermissions();

  const [tab, setTab] = useState(location.search ? 2 : 1);
  const [payrollSummaryGraphData, setPayrollSummaryGraphData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedRowID] = useState(null);

  const [employees, setEmployees] = useState([]);

  const [updatedSalaries, setUpdatedSalaries] = useState(false);

  const payrollData = [];

  const employeesInfo = useSelector((state) => state.EmployeeInfo.employeesInfo);
  const loadingEmployeesInfo = useSelector((state) => state.EmployeeInfo.loadingEmployeesInfo);

  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);

  const paymentDetails = useSelector((state) => state.GetPaymentDetails.paymentDetails);
  const loadingPaymentDetails = useSelector((state) => state.GetPaymentDetails.loadingPaymentDetails);

  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);
  const loadingGET_EMPLOYEE_Details = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);

  const payrollSummary = useSelector((state) => state.PayrollSummary.payrollSummary);
  const loadingPayrollSummary = useSelector((state) => state.PayrollSummary.loadingPayrollSummary);

  const getTotalEmployees = useCallback(() => {
    return updatedSalaries ? employeesInfo?.filteredCount : employeesInfo?.count;
  }, [updatedSalaries, employeesInfo]);

  const handleSearchEmployee = (event) => {
    if (event.target.value !== "") {
      const filteredEmployee = employees.filter((item) => {
        return item.name.toLowerCase().includes(event.target.value.toLowerCase());
      });
      setEmployeeList(filteredEmployee);
    } else {
      setEmployeeList(employees);
    }
  };

  const getPayrollstatus = useCallback(() => {
    const lastPayroll = userAdditionalInfo?.lastPayroll;
    const lastPayrollFailure = lastPayroll?.failure;
    const lastPayrollStatus = lastPayroll?.status;
    return isEmptyObject(userAdditionalInfo)
      ? lastPayroll
        ? lastPayrollFailure
          ? "Transaction Failed"
          : !lastPayrollFailure && lastPayrollStatus == "pending"
            ? "Transaction In progress"
            : !lastPayrollFailure && lastPayrollStatus == "paid"
              ? "Transaction Successful"
              : "No Payroll Found"
        : "No Payroll Found"
      : "-";
  }, [userAdditionalInfo]);

  useEffect(() => {
    if (isEmptyObject(loggedInUser)) {
      dispatch(
        EmployeeInfo({
          customerID: loggedInUser?.customerId,
          rowsPerPage: 500,
          page: 0,
          sortBy: null,
          dist: {
            updated: updatedSalaries,
          },
        })
      );
    }
  }, [loggedInUser, updatedSalaries]);

  useEffect(() => {
    if (getEmployeeDetails.ok) {
      if (selectedRowID != null) {
        navigate(`/main/details/${selectedRowID}`);
      }
    }
  }, [getEmployeeDetails]);

  useEffect(() => {
    if (employeesInfo && Object.keys(employeesInfo).length && employeesInfo?.rows?.length) {
      employeesInfo.rows.forEach((employee) => {
        payrollData.push({
          picturePath: employee?.picturePath,
          pictureVersion: employee?.pictureVersion,

          id: employee.employeeId,
          profilePicture: `${baseUrl}/employee/${employee.employeeId}/logo?id=${employee?.pictureVersion}`,
          name: `${getValue(employee.firstName)} ${getValue(employee.lastName)}`,
          role: getValue(employee.jobTitle),
          email: getValue(employee.email),
          country: getValue(employee.country),
          costToCompany: getFieldValue(employee?.costToCompany),
          incrementDetails: getFieldValue(employee?.incrementDetails),
          bonus: getFieldValue(employee?.incrementDetails?.bonus),
          edgeJoiningDate: convertDate(employee.startDate),
        });
      });
    }

    setEmployees(payrollData);
    setEmployeeList(payrollData);
  }, [employeesInfo]);

  useEffect(() => {
    if (Object.keys(paymentDetails).length && paymentDetails?.ok) {
      selectPageHeading("Payroll Details");
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (isEmptyObject(loggedInUser)) {
      const reqUrl = {
        edge: `user/${loggedInUser.userId}/summary`,
        customer: `customer/${loggedInUser.customerId}/info`,
        employee: `employee/${loggedInUser.userId}`,
      };
      dispatch(UserAdditionalInfo(reqUrl[loggedInUserType]));
    }

    dispatch(
      PayrollSummary({
        customerID: loggedInUser?.customerId,
        rowsPerPage: 6,
        page: 0,
      })
    );
  }, [loggedInUser]);

  useEffect(() => {
    const payrollDataFormatted = [];
    if (Object.keys(payrollSummary).length && payrollSummary?.ok && payrollSummary?.payload?.rows?.length) {
      payrollSummary.payload.rows.reverse().forEach((payroll) => {
        payrollDataFormatted.push({
          month: getMonthFromDate(payroll?.paymentDate),
          amount: payroll?.totalPayroll
            ? payroll?.totalBonus
              ? getValue(payroll?.totalPayroll) - getValue(payroll?.totalBonus)
              : getValue(payroll?.totalPayroll)
            : 0,
          bonus: payroll?.totalBonus ? getValue(payroll?.totalBonus) : 0,
        });
      });
    }
    setPayrollSummaryGraphData(payrollDataFormatted);
  }, [payrollSummary]);

  useEffect(() => {
    if (location.search) {
      setTab(2);
      return;
    }
    setTab(1);
  }, [location.search]);

  return (
    <>
      <Helmet>
        <title>Edge | Payroll</title>
      </Helmet>
      {loadingGET_EMPLOYEE_Details ||
      loadingAdditionalInfo ||
      loadingPayrollSummary ||
      loadingPaymentDetails ||
      loadingEmployeesInfo ? (
        <PageLoader />
      ) : null}
      <Grid container direction="row" rowSpacing={3} sx={{ background: "#FBFAFF" }}>
        <Grid
          container
          direction="column"
          rowSpacing={3}
          sx={{
            background: "white",
            marginTop: "29px",
            boxShadow: "0px 1px 6px rgba(2, 0, 110, 0.09)",
          }}
        >
          <PageTitle
            page={{
              title: `Payroll for ${getMonthFromDate(userAdditionalInfo?.paymentDate)}`,
              linkName: "Payroll",
              link: tab === 1 ? "/main/payroll" : "/main/payroll?history=true",
            }}
          />

          <PageToolBar
            tab1Title={{
              title: `Payroll for ${getMonthFromDate(userAdditionalInfo?.paymentDate)}`,
              navLink: "/main/payroll",
            }}
            tab2Title={{
              title: "Payroll History",
              navLink: "/main/payroll?history=true",
            }}
            tab={tab}
            setTab={setTab}
          />

          <Grid
            sx={{
              maxWidth: "1076px",
              width: "100%",
              margin: "0px auto",
              padding: "18px 0px 24px",
            }}
          >
            {tab === 1 && (
              <Grid sx={{ display: "flex", gap: "16px" }}>
                <Grid sx={{ width: "364px" }}>
                  <Grid
                    sx={{
                      height: "100%",
                      borderRadius: "24px",
                      background:
                        "linear-gradient(220.8deg, #FFEFD3 4.69%, #B4FFF2 43.22%, var(--color-primitives-primary-100) 85.44%)",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Grid sx={{ display: "flex", gap: "40px", padding: "8px 22px" }}>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "28px",
                            color: "#212231",
                            whiteSpace: "nowrap",
                          }}
                          id="total-payroll"
                        >
                          $ {currencyFormat(getFieldValue(userAdditionalInfo?.totalPayroll))}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#212231",
                          }}
                          id="payment-due-date"
                        >
                          Due on {convertDate(userAdditionalInfo?.paymentDate)}
                        </Typography>
                      </Grid>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "5px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "28px",
                            color: "#212231",
                            whiteSpace: "nowrap",
                          }}
                          id="total-employees"
                        >
                          {getFieldValue(userAdditionalInfo?.totalEmployees)}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#212231",
                          }}
                        >
                          Employee(s)
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider sx={{ borderColor: "#FFFFFF" }} />
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#212231",
                        letterSpacing: "0.4px",
                        padding: "12px 22px",
                      }}
                    >
                      Total Payroll
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sx={{ width: "456px", display: "flex" }}>
                  <Grid
                    sx={{
                      height: "100%",
                      borderRadius: "24px",
                      background: "white",
                      display: "flex",
                      flexDirection: "column",
                      width: "170px",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        padding: "8px 22px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins-SemiBold",
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "28px",
                          color: "#212231",
                          whiteSpace: "nowrap",
                        }}
                        id="bonus-added"
                      >
                        $ {currencyFormat(getFieldValue(userAdditionalInfo?.totalBonus))}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "16px",
                          color: "#212231",
                        }}
                      >
                        Bonus Added
                      </Typography>
                    </Grid>
                    <Divider sx={{ borderColor: "#C7C5D0" }} />
                    <Typography
                      id="no-of-emp-bonus"
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#212231",
                        letterSpacing: "0.4px",
                        padding: "8px 22px",
                      }}
                    >
                      {getFieldValue(userAdditionalInfo?.updatedEmployeePayrolls?.filteredCountBonus)}{" "}
                      <span style={{ fontFamily: "Poppins-Regular" }}>employee(s)</span>
                    </Typography>
                  </Grid>
                  <Grid
                    sx={{
                      height: "100%",
                      borderRadius: "24px",
                      background: "white",
                      display: "flex",
                      flexDirection: "column",
                      width: "170px",
                    }}
                  >
                    <Grid
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                        padding: "8px 22px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Poppins-SemiBold",
                          fontWeight: 600,
                          fontSize: "20px",
                          lineHeight: "28px",
                          color: "#212231",
                          whiteSpace: "nowrap",
                        }}
                        id="raise-added"
                      >
                        $ {currencyFormat(getFieldValue(userAdditionalInfo?.totalIncrementRaise))}
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: "Poppins-Regular",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "16px",
                          color: "#212231",
                        }}
                      >
                        Raise Added
                      </Typography>
                    </Grid>
                    <Divider sx={{ borderColor: "#C7C5D0" }} />

                    <Typography
                      sx={{
                        fontFamily: "Poppins-SemiBold",
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        color: "#212231",
                        letterSpacing: "0.4px",
                        padding: "8px 22px",
                      }}
                      id="no-of-emp-raise"
                    >
                      {getFieldValue(userAdditionalInfo?.updatedEmployeePayrolls?.filteredCountIncrement)}{" "}
                      <span style={{ fontFamily: "Poppins-Regular" }}>employee(s)</span>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {tab === 2 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid
                    sx={{
                      background: "#FBFAFF",
                      borderRadius: "24px",
                      padding: "4px 14px",
                      height: "100%",
                    }}
                  >
                    <Grid
                      sx={{
                        maxWidth: "320px",
                        height: "100%",
                      }}
                    >
                      <BarChartV2 payrollData={payrollSummaryGraphData} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid
                    sx={{
                      background: "#FBFAFF",
                      borderRadius: "24px",
                      padding: "4px 14px",
                      display: "flex",
                      alignItems: "center",
                      gap: "18px",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={platfromRebrandPhase1 ? `${s3ImagesBaseURL}/give-bonus.svg` : giftImage}
                      sx={{ width: "104px" }}
                      height="104px"
                      alt="Gift Image"
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Regular",
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#000000",
                      }}
                    >
                      You can give bonuses to your well performing employees. Let them know they are valued at your
                      company
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid direction="column" container rowSpacing={3} mt={0}>
          {tab === 1 && (
            <>
              <Grid container sx={{ backgroundColor: "var(--color-primitives-primary-50)" }}>
                <Grid
                  sx={{
                    maxWidth: "1076px",
                    width: "100%",
                    margin: "0px auto",
                    padding: "16px 0px",
                  }}
                >
                  {userAdditionalInfo?.lastPayroll && (
                    <Grid
                      sx={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: "12px",
                        display: "flex",
                      }}
                    >
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          minWidth: "160px",
                          padding: "16px 6px 16px 22px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-SemiBold",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "24px",
                            color: "#212231",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {getMonthFromDate(userAdditionalInfo?.lastPayroll?.payrollMonth)}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular",
                            fontWeight: 400,
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "#212231",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Last Month Payroll
                        </Typography>
                      </Grid>
                      <Divider orientation="vertical" variant="fullWidth" flexItem />
                      <Grid
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Grid sx={{ display: "flex", gap: "30px" }}>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              minWidth: "144px",
                              padding: "16px 22px",
                              pr: "0px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontWeight: 500,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#212231",
                                whiteSpace: "nowrap",
                              }}
                            >
                              $ {currencyFormat(getFieldValue(userAdditionalInfo?.lastPayroll?.totalAmount))}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Poppins-Regular",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#212231",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Paid on {convertDate(userAdditionalInfo?.lastPayroll?.paymentDate)}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              minWidth: "144px",
                              padding: "16px 0px",
                              pl: "64px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontWeight: 500,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#212231",
                                whiteSpace: "nowrap",
                              }}
                            >
                              $ {currencyFormat(getFieldValue(userAdditionalInfo?.lastPayroll?.totalBonus))}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Poppins-Regular",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#212231",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Bonus added
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                              minWidth: "144px",
                              padding: "16px 0px",
                              pl: "60px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Poppins-SemiBold",
                                fontWeight: 500,
                                fontSize: "20px",
                                lineHeight: "28px",
                                color: "#212231",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {getValue(userAdditionalInfo?.lastPayroll?.totalEmployees)}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: "Poppins-Regular",
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "16px",
                                color: "#212231",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Number of Employees
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "18px",
                            pr: "30px",
                          }}
                        >
                          {getPayrollstatus() === "Transaction Failed" ? (
                            <Button
                              type="button"
                              variant="outlined"
                              size="xs"
                              id="retry-failed-payment"
                              style={{
                                color: "#BA1A1A",
                              }}
                              leadIcon={
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M5.44281 12.6667L6.9714 14.1953L6.0286 15.1381L2.89052 12L6.0286 8.86194L6.9714 9.80475L5.44281 11.3333H11.1667C12.2712 11.3333 13.1667 10.4379 13.1667 9.33334V7.33334H14.5V9.33334C14.5 11.1743 13.0076 12.6667 11.1667 12.6667H5.44281ZM11.5579 3.33401L10.0286 1.80475L10.9714 0.861938L14.1095 4.00001L10.9714 7.13808L10.0286 6.19527L11.5565 4.66734H5.83333C4.72876 4.66734 3.83333 5.56277 3.83333 6.66734V8.66734H2.5V6.66734C2.5 4.82639 3.99238 3.33401 5.83333 3.33401H11.5579Z"
                                    fill="#BA1A1A"
                                  />
                                </svg>
                              }
                              onClick={() =>
                                handleRetryPayment(
                                  loggedInUser?.customerId,
                                  userAdditionalInfo?.lastPayroll?.customerPayrollId,
                                  dispatch,
                                  RetryPayment
                                )
                              }
                            >
                              Retry
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              size="sm"
                              style={{
                                paddingTop: "4px",
                                paddingBottom: "4px",
                                color: "#00A294",
                              }}
                            >
                              {getPayrollstatus()}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid
                sx={{
                  maxWidth: "1076px",
                  width: "100%",
                  margin: "0px auto",
                  marginTop: "12px",
                }}
              >
                <Grid
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Grid sx={{ width: "340px" }}>
                      <TextField
                        type="search"
                        id="search-employees"
                        onChange={handleSearchEmployee}
                        placeholder="Search employees"
                        sx={{
                          ".MuiInputBase-root": {
                            backgroundColor: "white",
                            borderRadius: "99px",
                            borderColor: "#BFC2FF",
                            outlineColor: "#BFC2FF",
                            fontFamily: "Poppins-Regular",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "16px",
                            paddingRight: "15px",
                            height: "32px",
                          },
                          ".MuiOutlinedInput-input": {
                            padding: "8px 15px",
                            paddingRight: "6px",
                          },
                        }}
                        InputProps={{
                          disableUnderline: true,
                          endAdornment: (
                            <InputAdornment position="center">
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  fill: "#131599",
                                }}
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14.9066 13.6148L19.8274 18.5355L18.5356 19.8272L13.6149 14.9065C12.3775 15.8684 10.8227 16.4412 9.13409 16.4412C5.09844 16.4412 1.8269 13.1696 1.8269 9.13397C1.8269 5.09832 5.09844 1.82678 9.13409 1.82678C13.1697 1.82678 16.4413 5.09832 16.4413 9.13397C16.4413 10.8226 15.8685 12.3774 14.9066 13.6148ZM9.13409 14.6144C12.1608 14.6144 14.6145 12.1607 14.6145 9.13397C14.6145 6.10724 12.1608 3.65358 9.13409 3.65358C6.10736 3.65358 3.6537 6.10724 3.6537 9.13397C3.6537 12.1607 6.10736 14.6144 9.13409 14.6144Z"
                                  fill="#131599"
                                />
                              </svg>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Button
                      type="button"
                      variant="outlined"
                      id="view-updated-salaries"
                      size="md"
                      onClick={() => setUpdatedSalaries(!updatedSalaries)}
                      style={{
                        height: "32px",
                        backgroundColor: updatedSalaries && "var(--color-primitives-primary-50)",
                      }}
                    >
                      View Updated Salaries
                    </Button>
                  </Grid>
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    id="edit-payroll-for-month"
                    leadIcon={
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M9.22674 5.9589L3.52208 11.6622C3.21821 11.9309 3.03023 12.3069 3 12.6509V15.0022L5.29799 15.0036C5.69866 14.9753 6.07162 14.7888 6.37109 14.4462L12.0425 8.77479L9.22674 5.9589ZM10.2875 4.89836L13.1032 7.71413L14.8986 5.91872C14.9635 5.85376 15 5.76567 15 5.67381C15 5.58195 14.9635 5.49386 14.8986 5.4289L12.5708 3.10114C12.5066 3.03641 12.4192 3 12.328 3C12.2368 3 12.1494 3.03641 12.0852 3.10114L10.2875 4.89836ZM12.328 1.5C12.8191 1.5 13.2898 1.69606 13.6336 2.04257L15.9593 4.36824C16.3055 4.7145 16.5 5.18413 16.5 5.67381C16.5 6.16349 16.3055 6.63312 15.9593 6.97938L7.46813 15.4678C6.94427 16.0721 6.20167 16.4434 5.35095 16.5017H1.5V15.7517L1.50244 12.5905C1.56633 11.7996 1.934 11.0642 2.49487 10.5701L11.0215 2.04347C11.3673 1.69562 11.8375 1.5 12.328 1.5Z"
                          fill="white"
                        />
                      </svg>
                    }
                    onClick={() => navigate("/main/customers/editPayroll")}
                  >
                    Edit {getMonthFromDate(userAdditionalInfo?.paymentDate)} payroll
                  </Button>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  maxWidth: "1076px",
                  width: "100%",
                  margin: "0px auto",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "20px",
                    fontFamily: "Poppins-Regular",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    color: "#212231",
                  }}
                  id="total-emp-selected-month"
                >
                  Total employees on{" "}
                  <span style={{ fontWeight: 600 }}>{getMonthFromDate(userAdditionalInfo?.paymentDate)}</span> payroll (
                  {getTotalEmployees()})
                </Typography>
              </Grid>
              <Grid
                sx={{
                  maxWidth: "1076px",
                  width: "100%",
                  margin: "0px auto",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    paddingTop: "15px",
                    paddingBottom: "30px",
                  }}
                >
                  {employeeList?.length > 0 &&
                    employeeList.map((employee) => <EmployeePayrollCard employee={employee} />)}
                </Grid>
              </Grid>
            </>
          )}
          {tab === 2 && (
            <Grid
              sx={{
                maxWidth: "1076px",
                width: "100%",
                margin: "0px auto",
                height: "calc(100vh - 346px)",
              }}
            >
              <Typography
                sx={{
                  marginTop: "20px",
                  fontFamily: "Poppins-Regular",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#212231",
                }}
              >
                Your payments this year
              </Typography>
              <PayrollHistory />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Payroll;
