import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useAuth0 } from "@auth0/auth0-react";

import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
  Alert,
  List,
  ListItem,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { EmailOutlined, VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";

import { SignInScreenPermissions } from "components/Screens/Login/SignIn.enum";
import MicrosoftSignup from "components/Screens/Login/SignUp/MicrosoftSignup";
import GoogleSignup from "components/Screens/Login/SignUp/GoogleSignup";
import TextFieldV2 from "components/common/TextFieldV2/TextFieldV2";
import PageLoader from "components/common/PageLoader/index";
import TermsAndPolicy from "components/common/TermAndPolicy";
import EdgeLogo from "components/Design/EdgeLogo/EdgeLogo";
import Auth0Login from "components/Screens/Login/SignUp/Auth0Login/Auth0Login";
import { AlertType } from "components/common/Snackbar";

import { AuthUser, ResetAuthUser, AuthUserSuccess } from "store/actions/AuthUser";
import { UserAdditionalInfo } from "store/actions/UserAdditionalInfo";
import { ResetGoogleSigunp } from "store/actions/Google/Google_signup";
import { GetPlatformConfig } from "store/actions/PlatformConfig";
import { showSnackbar } from "store/actions/Utility";

import loginWithAuth0 from "services/auth0";
import { baseUrl } from "util/APIBaseUrl";
import { USER_TYPES } from "constants";

import loginArtWork from "assets/images/login-art-work.png";

import "components/Screens/Login/SignIn/SignInV2.scss";

const userCredentials = {
  email: "",
  password: "",
  loginPortal: null,
};

function SignIn(props) {
  const { changeScreen } = props;
  const { handleSubmit } = useForm();
  const { platfromRebrandPhase1 } = useFlags();

  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const location = useLocation();
  const flags = useFlags();
  const { isAuthenticated: isAuth0Authenticated, getAccessTokenSilently } = useAuth0();

  const [showPassword, setShowPassword] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const [userCredential, setUserCredential] = React.useState(userCredentials);
  const [loginButtonClicked, setLoginButtonClicked] = React.useState(false);
  const [isAuth0Loading, setIsAuth0Loading] = useState(false);
  const [tab, setTab] = React.useState(0);

  const authUserData = useSelector((state) => state.AuthUser.authUserData);
  const loadingAuthUserData = useSelector((state) => state.AuthUser.loadingAuthUserData);
  const platformConfig = useSelector((state) => state.PlatformConfig.platformConfig);
  const loadingplatformConfig = useSelector((state) => state.PlatformConfig.loadingplatformConfig);
  const userAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.userAdditionalInfo);
  const loadingAdditionalInfo = useSelector((state) => state.UserAdditionalInfo.loadingAdditionalInfo);
  const googleSignupInfo = useSelector((state) => state.GoogleSignup.googleSignupInfo);
  const loadingGoogleSignupInfo = useSelector((state) => state.GoogleSignup.loadingGoogleSignupInfo);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setUserCredential({
      ...userCredential,
      [event.target.name]: value,
    });
  };

  const handleTabChange = (value) => {
    setTab(value);
  };

  const getError = (errors) => {
    if (errors != null && errors != undefined) {
      return errors.map((error) => {
        return (
          <List
            sx={{
              listStyleType: "disc",
              paddingRight: "0px !important",
              pl: 3,
              "& .MuiListItem-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
                paddingTop: "0px",
                paddingBottom: "0px",
              },

              "&.MuiList-root": {
                paddingTop: "0px",
                paddingBottom: "0px",
              },
            }}
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              {(() => {
                if (error?.msg) return error.msg;
                if (error?.description) return error.description;
                return "Undefined Error";
              })()}
              {error?.param ? error?.param : " "}
            </ListItem>
          </List>
        );
      });
    }
    return null;
  };

  // eslint-disable-next-line consistent-return
  const checkError = (field, view) => {
    if (loginButtonClicked) {
      if (view != "textField") {
        if (userCredential[field]?.key != null) {
          return false;
        }
        return true;
      }
      if (userCredential[field] != "") {
        return false;
      }
      return true;
    }
    return false;
  };

  const loginIntoAuth0 = useCallback(async () => {
    setIsAuth0Loading(true);
    const token = await getAccessTokenSilently();
    try {
      const resp = await loginWithAuth0(token);
      dispatch(AuthUserSuccess(resp));
    } catch (error) {
      dispatch(
        showSnackbar({
          type: AlertType.error,
          message: error.message,
          dispatch,
        })
      );
    } finally {
      setIsAuth0Loading(false);
    }
  }, [getAccessTokenSilently, dispatch]);

  const checkCodeInParams = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");
    if (code) {
      if (searchParams.get("state")) {
        // employee redirected back from auth0
        setIsAuth0Loading(true);
        if (isAuth0Authenticated) {
          loginIntoAuth0();
        }
      } else {
        // employee redirected back from Payoneer
        localStorage.setItem("payoneerCode", code);
        navigateTo("/main/dashboard");
      }
    }
  }, [location.search, isAuth0Authenticated, loginIntoAuth0, navigateTo]);

  // maps browser route on to api call: Defaults to Customer  type login
  const routesMap = {
    0: "customer",
    1: "employee",
  };

  const loginHandler = () => {
    setLoginButtonClicked(true);
    dispatch(ResetGoogleSigunp({}));
    dispatch(ResetAuthUser({}));
    if (userCredential.email != "" && userCredential.password != "") {
      setOpenAlert(false);
      const loginPortal = routesMap[tab] ? routesMap[tab] : "admin";
      const request = `${baseUrl}/auth/${loginPortal}`;

      dispatch(
        AuthUser({
          userCredential,
          request,
          loginPortal,
        })
      );
    } else {
      setOpenAlert(true);
    }
  };

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");
    let request = "";

    if (accesstoken && loggedInUser) {
      if (loggedInUser?.type == "customer") {
        request = `customer/${loggedInUser.customerId}/info`;
      } else if (loggedInUser?.type == "edge") {
        request = `user/${loggedInUser.userId}/summary`;
      } else if (loggedInUser?.type == "employee") {
        request = `employee/${loggedInUser.userId}`;
      }
      dispatch(UserAdditionalInfo(request));
    }

    if (Object.keys(authUserData).length > 0 && authUserData.ok != true) {
      setOpenAlert(true);
    }
  }, [authUserData]);

  React.useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    const accesstoken = localStorage.getItem("accessToken");
    localStorage.setItem("communityLoginCheck", false);
    if (userAdditionalInfo && Object.keys(userAdditionalInfo).length && accesstoken && loggedInUser) {
      window.analytics?.track("Logged In", {
        channel: "password",
        name: loggedInUser?.name,
        email: loggedInUser?.email,
        userID: loggedInUser?.userId,
        accountType: loggedInUser?.type,
      });

      if (loggedInUser?.type == "customer") {
        if (!loggedInUser?.bankAttached && userAdditionalInfo.status === "enabled") {
          navigateTo("/addPaymentMethod");
        } else {
          navigateTo("/main/dashboard");
        }
      } else if (loggedInUser?.type == "edge") {
        navigateTo("/main/dashboard");
      } else if (loggedInUser?.type == "employee") {
        navigateTo("/main/dashboard");
      }
    }
    if (userAdditionalInfo && Object.keys(userAdditionalInfo).length > 0 && userAdditionalInfo.ok != true) {
      setOpenAlert(true);
    }
  }, [userAdditionalInfo]);

  useEffect(() => {
    dispatch(GetPlatformConfig({}));
  }, [dispatch]);

  useEffect(() => {
    checkCodeInParams();
  }, [isAuth0Authenticated, checkCodeInParams]);

  return (
    <>
      <Helmet>
        <title>Edge | Sign In</title>
      </Helmet>

      {isAuth0Loading === true ||
      loadingAuthUserData == true ||
      loadingAdditionalInfo == true ||
      loadingplatformConfig == true ||
      loadingGoogleSignupInfo == true ? (
        <PageLoader />
      ) : null}
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          backgroundColor: "#FFFFFF",
          overflowY: "auto",
        }}
      >
        <Grid
          item
          xl={7}
          lg={7}
          md={7}
          sm={12}
          xs={12}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          padding="20px 0px"
        >
          <Stack>
            <form onSubmit={handleSubmit(loginHandler)}>
              <Grid item container direction="column">
                <Grid item>
                  {platfromRebrandPhase1 ? (
                    <div className="mb-3">
                      <EdgeLogo size="lg" state="dark" />
                    </div>
                  ) : (
                    <Box
                      component="img"
                      sx={{
                        height: "auto",
                        maxWidth: "100%",
                      }}
                      alt="edge"
                      src="assets/Images/edge-logo.svg"
                    />
                  )}
                </Grid>
                <Grid item>
                  <Box>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                      <Tab
                        label="Log In as Customer"
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "transparent !important",
                          },
                          paddingLeft: "0px",
                          fontFamily: "Poppins-Medium",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        onClick={() => setTab(0)}
                      />
                      <Tab
                        label="Log In as Employee"
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "transparent !important",
                          },
                          paddingLeft: "0px",
                          marginLeft: "14px",
                          fontFamily: "Poppins-Medium",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        onClick={() => setTab(1)}
                      />
                    </Tabs>
                  </Box>
                </Grid>

                <Grid sx={{ maxWidth: "318px", width: "100%" }}>
                  <Grid item sx={{ marginTop: "20px !important" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins-Medium !important",
                        color: "#000000",
                        fontSize: "32px",
                        fontWeight: "600",
                        lineHeight: "44px",
                      }}
                    >
                      Sign In
                    </Typography>
                  </Grid>

                  <Grid item mt="20px">
                    <FormControl variant="outlined" fullWidth error>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #B2B4E2",
                          borderRadius: "12px",
                        }}
                      >
                        <EmailOutlined
                          sx={{
                            color: "#3D3E5C",
                            marginLeft: "18px",
                          }}
                        />
                        <TextFieldV2
                          label="Email Address"
                          variant="filled"
                          name="email"
                          id="email"
                          type="text"
                          onChange={handleInputChange}
                          error={checkError("email", "textField")}
                          sx={{
                            width: "100%",
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item mt="18px">
                    <FormControl variant="outlined" fullWidth>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #B2B4E2",
                          borderRadius: "12px",
                          paddingLeft: "4px",
                        }}
                      >
                        <TextFieldV2
                          label="Password"
                          variant="filled"
                          name="password"
                          id="password"
                          type={showPassword ? "text" : "password"}
                          onChange={handleInputChange}
                          error={checkError("password", "textField")}
                          InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {showPassword ? (
                                    <VisibilityOutlined
                                      sx={{
                                        color: "#3D3E5C",
                                      }}
                                    />
                                  ) : (
                                    <VisibilityOffOutlined
                                      sx={{
                                        color: "#3D3E5C",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </FormControl>
                  </Grid>

                  <Grid item mt="20px">
                    <Grid display="flex" alignItems="center" justifyContent="space-between">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 22,
                                },
                              }}
                            />
                          }
                          label="Remember me"
                        />
                      </FormGroup>
                      <Button
                        sx={{
                          paddingTop: "5px",
                          color: "#000000",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontFamily: "Poppins-Medium !important",
                          cursor: "pointer !important",
                          textDecoration: "none",
                          background: "none",
                          border: "none",
                          padding: 0,
                          minWidth: "auto",
                        }}
                        onClick={() => {
                          changeScreen(
                            tab === 0
                              ? SignInScreenPermissions.FORGOT_PASSWORD
                              : SignInScreenPermissions.FORGOT_PASSWORD_EMPLOYEE
                          );
                        }}
                      >
                        <Typography
                          fontWeight={500}
                          color="#000000"
                          sx={{
                            fontFamily: "Poppins-Medium !important",
                            fontSize: "13px",
                            lineHeight: "8px",
                          }}
                        >
                          Forgot Password?
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  {authUserData?.ok == false || googleSignupInfo?.ok == false ? (
                    <Grid item mt="20px">
                      <Alert
                        open={openAlert}
                        autoHideDuration={3500}
                        variant="filled"
                        icon={false}
                        sx={{
                          backgroundColor: "#F64A14",
                          padding: "initial",
                          px: "5px",
                          fontSize: "12px",
                          textAlign: "center",
                          borderRadius: "5px !important",
                          color: "#ffffff !important",
                          "&.MuiPaper-root": {
                            color: "#ffffff !important",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Poppins-Regular !important",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          {(() => {
                            if (authUserData?.ok == false) {
                              return getError(authUserData?.payload?.error);
                            }
                            if (googleSignupInfo?.ok == false) {
                              return getError(googleSignupInfo?.payload?.error);
                            }
                            return "Enter all information before proceeding";
                          })()}
                        </Typography>
                      </Alert>
                    </Grid>
                  ) : null}

                  <Grid item mt="20px">
                    <Button
                      type="submit"
                      variant="contained"
                      fullWidth
                      sx={{
                        backgroundColor: "var(--color-primitives-primary-600)",
                        height: "54px",
                        fontFamily: "Poppins-Regular !important",
                        fontWeight: "500",
                        lineHeight: "20px",
                        borderRadius: "99px",
                        fontSize: "16px",
                        color: "white",
                        boxShadow: "0px 1px 14px rgba(2, 0, 110, 0.12)",
                      }}
                    >
                      Continue
                    </Button>
                  </Grid>

                  <Divider sx={{ margin: "20px 0px" }} />

                  {tab === 1 && (
                    <Grid item>
                      {platformConfig.ok && (
                        <Grid item className="auth0-button-container">
                          {location.pathname === "/" && flags.replaceGoogleWithAuth0 ? (
                            <Auth0Login />
                          ) : (
                            <GoogleSignup
                              changeScreen={changeScreen}
                              loginPortal={tab == 0 ? "customer" : "employee"}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {tab === 0 && (
                    <Grid item>
                      {platformConfig.ok && (
                        <Grid
                          item
                          sx={{
                            paddingTop: "8px !important",
                          }}
                        >
                          {location.pathname == "/" ? (
                            <GoogleSignup
                              changeScreen={changeScreen}
                              loginPortal={tab == 0 ? "customer" : "employee"}
                            />
                          ) : null}
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </form>
            <Grid item container direction="column">
              <Grid sx={{ maxWidth: "318px", width: "100%" }}>
                <Grid item>
                  {platformConfig.ok && (
                    <Grid
                      item
                      sx={{
                        paddingTop: "12px !important",
                      }}
                    >
                      {location.pathname == "/" &&
                      platformConfig?.payload?.MICROSOFT_LOGIN_ENABLED === "1" &&
                      tab == 0 ? (
                        <MicrosoftSignup changeScreen={changeScreen} loginPortal={tab == 0 ? "customer" : "employee"} />
                      ) : null}
                    </Grid>
                  )}
                </Grid>
                <Divider sx={{ margin: "20px 0px" }} />
                <TermsAndPolicy userType={tab === 0 ? USER_TYPES.CUSTOMER : USER_TYPES.EMPLOYEE} />
                <Grid item mt="20px">
                  <Typography
                    sx={{
                      fontFamily: "Poppins-Regular",
                      fontSize: "12px",
                      fontWeight: 400,
                      lineHeight: "16px",
                      textAlign: "center",
                      color: "#B3B3B3",
                    }}
                  >
                    ©️ Copyright 2010 - 2023 Edge, LLC
                  </Typography>
                </Grid>
              </Grid>
              <Grid item textAlign="center">
                <Typography variant="h3Lite" fontSize="9px">
                  {process.env.REACT_APP_NAME}
                  {` ${process.env.REACT_APP_VERSION}`}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Grid>

        <Grid
          item
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
          backgroundColor="var(--color-primitives-primary-600)"
          minHeight="100vh"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              maxWidth: "528px",
              width: "100%",
              objectFit: "contain",
              backgroundColor: "var(--color-primitives-primary-600)",
              "@media screen and (min-width: 912px)": {
                objectFit: "cover",
                maxWidth: "400px",
                width: "100%",
              },
            }}
            alt={platfromRebrandPhase1 ? "Login Artwork" : "The house from the offer."}
            src={platfromRebrandPhase1 ? loginArtWork : "assets/images/login-image.png"}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default SignIn;
